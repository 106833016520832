import { IconProps } from '@/assets/Icons/IconProps';

const SVG = ({ fill = '#5B5B5B' }: IconProps) => {
  return (
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.437256" width="35.1256" height="35.1256" rx="17.5628" fill={fill} />
      <path
        d="M7.45117 18H24.4817M24.4817 18L17.5631 11.0813M24.4817 18L17.5631 24.9186"
        stroke="black"
        strokeWidth="3.19323"
      />
    </svg>
  );
};

export default SVG;
