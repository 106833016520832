import { IconProps } from '@/assets/Icons/IconProps';

const SecondIcon = ({ isMobile }: IconProps) => (
  <svg width={isMobile ? 14 : 23} height={isMobile ? 12 : 21} fill="none">
    <path
      d={
        isMobile
          ? 'M5.796 2.275 7.2 1.808l.757 2.276.005.014.015-.005 1.399-.463.014-.005-.005-.014-.758-2.276 1.394-.466a.353.353 0 0 1 .446.224l1.392 4.175a.353.353 0 0 1-.223.446L7.923 6.951a3.372 3.372 0 0 0-1.132-.564L5.573 2.72a.353.353 0 0 1 .223-.446Zm7.209 4.55.235.701a.353.353 0 0 1-.223.446L8.102 9.613l-.01.003v.011a2.196 2.196 0 0 1-2.596 2.12 2.207 2.207 0 0 1-1.769-1.813 2.181 2.181 0 0 1 .824-2.071l.009-.007-.004-.01L2.432 1.47l-.004-.01H.368a.354.354 0 0 1-.353-.354V.37c0-.195.16-.354.354-.354h2.58c.312 0 .587.199.687.495l2.29 6.871.003.01h.011a2.187 2.187 0 0 1 1.682.847l.007.009.01-.004 4.92-1.64a.353.353 0 0 1 .446.223ZM4.778 9.587a1.122 1.122 0 1 0 2.243 0 1.122 1.122 0 0 0-2.243 0Z'
          : 'm10.043 3.942 2.434-.81 1.313 3.944.009.025.025-.008 2.423-.803.026-.008-.009-.026-1.313-3.943 2.415-.806a.61.61 0 0 1 .773.386l2.411 7.235a.61.61 0 0 1-.386.773l-6.435 2.144a5.843 5.843 0 0 0-1.961-.979l-2.112-6.35a.61.61 0 0 1 .387-.774Zm12.492 7.885.407 1.214a.613.613 0 0 1-.387.773l-8.516 2.843-.018.006v.019a3.805 3.805 0 0 1-4.497 3.672c-1.562-.274-2.823-1.579-3.065-3.14-.226-1.464.392-2.78 1.427-3.59l.015-.01-.006-.019L4.214 2.547l-.006-.018H.638a.614.614 0 0 1-.611-.612V.639c0-.337.275-.612.612-.612H5.11c.54 0 1.016.344 1.189.856l3.968 11.907.006.018h.02a3.789 3.789 0 0 1 2.913 1.467l.012.015.018-.006 8.525-2.843a.61.61 0 0 1 .773.386ZM8.279 16.61a1.944 1.944 0 1 0 3.887 0 1.944 1.944 0 0 0-3.887 0Z'
      }
      fill="#5B5B5B"
      stroke="#5B5B5B"
      strokeWidth={0.053}
    />
  </svg>
);

export default SecondIcon;
