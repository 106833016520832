import { IconProps } from '@/assets/Icons/IconProps';

const SVG = ({ fill = '#252525', viewBox = '0 0 43.13 43.13' }: IconProps) => {
  return (
    <svg viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
      <rect x="0.461914" width="43.1306" height="43.1306" rx="7.52021" fill={fill} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1077 9.95312C17.5433 9.95312 18.4275 10.8948 18.4547 12.1311C18.4547 13.3419 17.5433 14.3107 16.08 14.3107H16.0532C14.6443 14.3107 13.7334 13.3419 13.7334 12.1311C13.7334 10.8948 14.672 9.95312 16.1077 9.95312ZM13.9796 16.0311H18.1763V28.6406H13.9796V16.0311ZM33.3105 21.4122C33.3105 17.5391 31.2401 15.7365 28.4785 15.7365C26.2503 15.7365 25.2527 16.9604 24.6957 17.819V16.0329H20.4984C20.5537 17.2161 20.4984 28.6424 20.4984 28.6424H24.6957V21.6002C24.6957 21.2234 24.7229 20.8474 24.8339 20.5776C25.1372 19.8247 25.8278 19.0453 26.9872 19.0453C28.5064 19.0453 29.1137 20.2015 29.1137 21.896V28.642H33.3103L33.3105 21.4122Z"
        fill="white"
      />
    </svg>
  );
};

export default SVG;
