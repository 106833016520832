import { IconProps } from '@/assets/Icons/IconProps';

const FourthIcon = ({ isMobile }: IconProps) => (
  <svg width={isMobile ? 15 : 25} height={isMobile ? 12 : 21} fill="none">
    <path
      d={
        isMobile
          ? 'M9.79 3.825c.837 1.116.837 3.28 0 4.395M11.674 1.628c2.504 2.391 2.52 6.415 0 8.79M1 7.881V4.165c0-.36.281-.653.628-.653h2.251a.616.616 0 0 0 .444-.192l1.884-2.127c.396-.412 1.072-.12 1.072.462v8.736c0 .587-.684.877-1.078.457L4.324 8.732a.616.616 0 0 0-.45-.197H1.629A.641.641 0 0 1 1 7.88v0Z'
          : 'M17.23 6.896c1.45 1.934 1.45 5.681 0 7.615M20.496 3.088c4.339 4.143 4.365 11.116 0 15.232M2 13.923V7.485c0-.626.487-1.133 1.088-1.133h3.901c.289 0 .566-.12.77-.332l3.264-3.686c.685-.714 1.857-.208 1.857.801v15.138c0 1.016-1.186 1.518-1.867.79L7.76 15.399a1.067 1.067 0 0 0-.779-.342H3.088c-.6 0-1.088-.507-1.088-1.133v0Z'
      }
      stroke="#5B5B5B"
      strokeWidth={isMobile ? 1.256 : 2.176}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default FourthIcon;
