const SVG = () => {
  return (
    <svg width={24} height={23} fill="none">
      <path
        d="m1.63 21.597 5.049-5.05"
        stroke="#fff"
        strokeWidth={2.634}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx={13.62}
        cy={9.606}
        r={8.204}
        stroke="#fff"
        strokeWidth={2.634}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SVG;
