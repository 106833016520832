import { IconProps } from './IconProps';

const SVG = ({ stroke = '#FFF', opacity = 0.4 }: IconProps) => {
  return (
    <svg viewBox="0 0 15 24" fill="none">
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeWidth={3.75}
        d="M13 2 3 12l10 10"
        opacity={opacity}
      />
    </svg>
  );
};

export default SVG;
