import { IconProps } from '@/assets/Icons/IconProps';

const SeventhIcon = ({ isMobile }: IconProps) => (
  <svg width={isMobile ? 14 : 24} height={isMobile ? 14 : 23} fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d={
        isMobile
          ? 'M10.5 0c.387 0 .7.304.7.678v.678h.7c1.16 0 2.1.91 2.1 2.034v8.135c0 1.124-.94 2.034-2.1 2.034H2.1c-1.16 0-2.1-.91-2.1-2.034V3.39c0-1.123.94-2.034 2.1-2.034h.7V.678A.69.69 0 0 1 3.5 0c.387 0 .7.304.7.678v.678h5.6V.678A.69.69 0 0 1 10.5 0Zm2.1 6.78H1.4v4.745a.69.69 0 0 0 .7.678h9.8a.69.69 0 0 0 .7-.678V6.78Zm-2.1 1.355c.387 0 .7.304.7.678v1.356a.69.69 0 0 1-.7.678H9.1a.69.69 0 0 1-.7-.678V8.813a.69.69 0 0 1 .7-.678h1.4ZM2.8 2.712h-.7a.69.69 0 0 0-.7.678v2.034h11.2V3.39a.69.69 0 0 0-.7-.678h-.7v.678a.69.69 0 0 1-.7.678.69.69 0 0 1-.7-.678v-.678H4.2v.678a.69.69 0 0 1-.7.678.69.69 0 0 1-.7-.678v-.678Z'
          : 'M17.625 0c.649 0 1.175.51 1.175 1.138v1.138h1.175c1.947 0 3.525 1.529 3.525 3.414v13.656c0 1.886-1.578 3.414-3.525 3.414H3.525C1.578 22.76 0 21.231 0 19.346V5.69c0-1.886 1.578-3.414 3.525-3.414H4.7V1.138C4.7.51 5.226 0 5.875 0S7.05.51 7.05 1.138v1.138h9.4V1.138C16.45.51 16.976 0 17.625 0Zm3.525 11.38H2.35v7.966c0 .628.526 1.138 1.175 1.138h16.45c.649 0 1.175-.51 1.175-1.138V11.38Zm-3.525 2.276c.649 0 1.175.51 1.175 1.138v2.276c0 .628-.526 1.138-1.175 1.138h-2.35c-.649 0-1.175-.51-1.175-1.138v-2.276c0-.629.526-1.138 1.175-1.138h2.35ZM4.7 4.552H3.525c-.649 0-1.175.51-1.175 1.138v3.414h18.8V5.69c0-.629-.526-1.138-1.175-1.138H18.8V5.69c0 .628-.526 1.138-1.175 1.138s-1.175-.51-1.175-1.138V4.552h-9.4V5.69c0 .628-.526 1.138-1.175 1.138S4.7 6.318 4.7 5.69V4.552Z'
      }
      fill="#5B5B5B"
    />
  </svg>
);

export default SeventhIcon;
