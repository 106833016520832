import { IconProps } from '@/assets/Icons/IconProps';

const FifthIcon = ({ isMobile }: IconProps) => (
  <svg width={isMobile ? 15 : 25} height={isMobile ? 9 : 16} fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d={
        isMobile
          ? 'M1.705 0H12.36a1.705 1.705 0 0 1 1.705 1.705v5.588a1.705 1.705 0 0 1-1.705 1.705H1.705A1.705 1.705 0 0 1 0 7.293V1.705A1.705 1.705 0 0 1 1.705 0Zm11.057 7.695a.569.569 0 0 0 .166-.402V1.705a.569.569 0 0 0-.568-.568H1.705a.568.568 0 0 0-.568.568v5.588a.568.568 0 0 0 .568.568H12.36c.15 0 .295-.06.402-.166Zm-5.67-1.528c.851 0 1.542-.829 1.542-1.85 0-1.022-.69-1.85-1.542-1.85-.851 0-1.542.828-1.542 1.85 0 1.021.69 1.85 1.542 1.85ZM3.7 4.317a.617.617 0 1 1-1.234 0 .617.617 0 0 1 1.234 0Zm7.4.617a.617.617 0 1 0 0-1.234.617.617 0 0 0 0 1.234Z'
          : 'M2.954 0h18.463a2.954 2.954 0 0 1 2.954 2.954v9.683a2.954 2.954 0 0 1-2.954 2.954H2.954A2.954 2.954 0 0 1 0 12.637V2.954A2.954 2.954 0 0 1 2.954 0Zm19.16 13.333a.985.985 0 0 0 .288-.696V2.954a.985.985 0 0 0-.985-.985H2.954a.985.985 0 0 0-.985.985v9.683a.984.984 0 0 0 .985.985h18.463a.985.985 0 0 0 .696-.289Zm-9.825-2.647c1.475 0 2.67-1.436 2.67-3.206s-1.195-3.206-2.67-3.206c-1.476 0-2.672 1.436-2.672 3.206s1.196 3.206 2.671 3.206ZM6.41 7.48a1.069 1.069 0 1 1-2.138 0 1.069 1.069 0 0 1 2.138 0Zm12.822 1.068a1.069 1.069 0 1 0 0-2.137 1.069 1.069 0 0 0 0 2.137Z'
      }
      fill="#5B5B5B"
    />
  </svg>
);

export default FifthIcon;
