import { IconProps } from '@/assets/Icons/IconProps';

const SVG = ({ fill = 'none', width = 144, height = 38, viewBox = '0 0 144 38' }: IconProps) => {
  return (
    <svg width={width} height={height} viewBox={viewBox} fill={fill}>
      <path
        d="M75.2085 1.31415C75.1572 0.852339 74.7814 0.510254 74.3203 0.510254H63.5764C63.1152 0.510254 62.7395 0.869443 62.6882 1.31415L59.5453 36.4976C59.4941 37.0108 59.904 37.4726 60.4335 37.4726H65.5749C66.0361 37.4726 66.4289 37.1134 66.4631 36.6345L66.7876 31.8795C66.8047 31.6571 66.9755 31.4861 67.1976 31.4861H70.682C70.9041 31.4861 71.0749 31.6571 71.092 31.8795L71.4165 36.6345C71.4507 37.0963 71.8435 37.4726 72.3047 37.4726H77.4631C77.9926 37.4726 78.4026 37.0279 78.3513 36.4976L75.2085 1.31415ZM67.7441 25.5851C67.505 25.5851 67.3171 25.3799 67.3342 25.1404L68.6153 7.86509C68.6323 7.72825 68.7348 7.64273 68.8544 7.64273H69.0252C69.1618 7.64273 69.2643 7.74536 69.2643 7.86509L70.5454 25.1404C70.5625 25.3799 70.3746 25.5851 70.1355 25.5851H67.7441Z"
        fill="#FBFBFB"
      />
      <path
        d="M140.853 1.31415C140.818 0.852339 140.425 0.510254 139.964 0.510254H129.22C128.759 0.510254 128.384 0.869443 128.332 1.31415L125.206 36.4976C125.155 37.0108 125.565 37.4726 126.095 37.4726H131.236C131.697 37.4726 132.09 37.1134 132.124 36.6345L132.449 31.8795C132.466 31.6571 132.637 31.4861 132.859 31.4861H136.343C136.565 31.4861 136.736 31.6571 136.753 31.8795L137.078 36.6345C137.112 37.0963 137.505 37.4726 137.966 37.4726H143.107C143.637 37.4726 144.047 37.0279 143.995 36.4976L140.853 1.31415ZM133.388 25.5851C133.149 25.5851 132.961 25.3799 132.978 25.1404L134.259 7.86509C134.276 7.72825 134.379 7.64273 134.498 7.64273H134.669C134.806 7.64273 134.908 7.74536 134.908 7.86509L136.189 25.1404C136.207 25.3799 136.019 25.5851 135.78 25.5851H133.388Z"
        fill="#FBFBFB"
      />
      <path
        d="M29.4133 22.2156V29.8612C29.4133 30.0835 29.6012 30.2717 29.8233 30.2717H35.6649C36.1602 30.2717 36.5531 30.6651 36.5531 31.1611V36.566C36.5531 37.0621 36.1602 37.4555 35.6649 37.4555H23.3325C22.8372 37.4555 22.4443 37.0621 22.4443 36.566V1.39968C22.4443 0.903652 22.8372 0.510254 23.3325 0.510254H35.6649C36.1602 0.510254 36.5531 0.903652 36.5531 1.39968V6.77042C36.5531 7.26644 36.1602 7.65984 35.6649 7.65984H29.8233C29.6012 7.65984 29.4133 7.84798 29.4133 8.07034V15.4423C29.4133 15.6646 29.6012 15.8528 29.8233 15.8528H34.4863C34.9817 15.8528 35.3745 16.2462 35.3745 16.7422V20.9156C35.3745 21.4117 34.9817 21.8051 34.4863 21.8051H29.8233C29.6012 21.8051 29.4133 21.9932 29.4133 22.2156Z"
        fill="#FBFBFB"
      />
      <path
        d="M97.1072 0.510254H81.854C81.3587 0.510254 80.9658 0.903652 80.9658 1.39968V6.77041C80.9658 7.26644 81.3587 7.65984 81.854 7.65984H85.5947C85.8168 7.65984 86.0047 7.84798 86.0047 8.07034V36.5831C86.0047 37.0792 86.3975 37.4726 86.8929 37.4726H92.0684C92.5637 37.4726 92.9566 37.0792 92.9566 36.5831V8.05323C92.9566 7.83088 93.1444 7.64273 93.3665 7.64273H97.1072C97.6025 7.64273 97.9954 7.24933 97.9954 6.75331V1.39968C97.9954 0.903652 97.6025 0.510254 97.1072 0.510254Z"
        fill="#FBFBFB"
      />
      <path
        d="M54.8312 30.2717H49.5532C49.3312 30.2717 49.1433 30.0835 49.1433 29.8612V1.39968C49.1433 0.903652 48.7504 0.510254 48.2551 0.510254H43.0796C42.5843 0.510254 42.1914 0.903652 42.1914 1.39968V36.5831C42.1914 37.0792 42.5843 37.4726 43.0796 37.4726H54.8312C55.3266 37.4726 55.7194 37.0792 55.7194 36.5831V31.1782C55.7194 30.6822 55.3266 30.2717 54.8312 30.2717Z"
        fill="#FBFBFB"
      />
      <path
        d="M116.699 20.0091V19.7868C116.699 19.6841 116.768 19.5986 116.853 19.5644C118.715 18.846 120.03 17.033 120.03 14.9291V7.23223C120.047 3.5206 117.041 0.510254 113.334 0.510254H103.94C103.445 0.510254 103.052 0.903652 103.052 1.39968V36.5831C103.052 37.0792 103.445 37.4726 103.94 37.4726H109.115C109.611 37.4726 110.004 37.0792 110.004 36.5831V23.7721C110.004 23.5497 110.192 23.3616 110.414 23.3616H111.626C112.446 23.3616 113.112 24.0286 113.112 24.8496V36.6003C113.112 37.0963 113.505 37.4897 114.001 37.4897H119.176C119.671 37.4897 120.064 37.0963 120.064 36.6003V24.9009C120.064 22.78 118.749 20.9841 116.887 20.2657C116.768 20.1973 116.699 20.1117 116.699 20.0091ZM113.095 15.9041C113.095 16.7251 112.429 17.3922 111.609 17.3922H110.397C110.174 17.3922 109.987 17.204 109.987 16.9817V8.05323C109.987 7.83088 110.174 7.64273 110.397 7.64273H111.609C112.429 7.64273 113.095 8.3098 113.095 9.1308V15.9041Z"
        fill="#FBFBFB"
      />
      <path
        d="M17.0125 7.23223C17.0125 3.5206 14.0063 0.510254 10.2827 0.510254H0.888203C0.392859 0.510254 0 0.903652 0 1.39968V36.5831C0 37.0792 0.392859 37.4726 0.888203 37.4726H12.2982C14.8945 37.4726 16.9954 35.3687 16.9954 32.7689V23.8234C16.9954 21.7024 15.6802 19.9065 13.8184 19.1881C13.7159 19.1539 13.6647 19.0684 13.6647 18.9658V18.7434C13.6647 18.6408 13.733 18.5553 13.8184 18.521C15.6802 17.8027 16.9954 15.9896 16.9954 13.8858L17.0125 7.23223ZM10.0606 28.8007C10.0606 29.6217 9.39445 30.2888 8.57457 30.2888H7.36183C7.13978 30.2888 6.95189 30.1006 6.95189 29.8783V22.2327C6.95189 22.0103 7.13978 21.8222 7.36183 21.8222H8.57457C9.39445 21.8222 10.0606 22.4892 10.0606 23.3102V28.8007ZM10.0606 14.3647C10.0606 15.1857 9.39445 15.8528 8.57457 15.8528H7.36183C7.13978 15.8528 6.95189 15.6646 6.95189 15.4423V8.05323C6.95189 7.83088 7.13978 7.64273 7.36183 7.64273H8.57457C9.39445 7.64273 10.0606 8.3098 10.0606 9.1308V14.3647Z"
        fill="#FBFBFB"
      />
    </svg>
  );
};

export default SVG;
