import { IconProps } from '@/assets/Icons/IconProps'

const SVG = ({
  fill = 'none',
  width = '97',
  height = '139',
  viewBox = '0 0 97 139',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M73.6855 63.5647C81.7448 65.7984 87.6294 69.7553 91.3393 75.4353C95.1131 81.1152 97 88.5503 97 97.7404C97 106.484 94.9212 113.983 90.7636 120.237C86.67 126.427 80.9453 131.118 73.5895 134.309C66.2977 137.436 57.9505 139 48.548 139C38.6977 139 30.1266 137.341 22.8348 134.022C15.543 130.703 9.91428 125.949 5.94857 119.758C1.98286 113.504 0 106.197 0 97.8361C0 88.646 1.88691 81.2429 5.66073 75.6267C9.49852 70.0106 15.3831 65.9899 23.3145 63.5647C17.6218 61.0119 13.2084 57.4061 10.0742 52.7472C7.00396 48.0246 5.46884 42.2489 5.46884 35.4201C5.46884 28.9105 7.00396 23.0071 10.0742 17.7101C13.2084 12.3492 18.0376 8.07323 24.5618 4.88223C31.0861 1.62741 39.1774 0 48.8358 0C58.3663 0 66.3297 1.62741 72.726 4.88223C79.1223 8.07323 83.8556 12.3492 86.9258 17.7101C89.996 23.0071 91.5312 28.9105 91.5312 35.4201C91.5312 42.185 89.9641 47.9288 86.8299 52.6515C83.6957 57.3104 79.3142 60.9481 73.6855 63.5647ZM48.548 18.4759C41.8958 18.4759 36.9067 20.1352 33.5806 23.4539C30.2545 26.7087 28.5915 31.3037 28.5915 37.239C28.5915 42.9828 30.3185 47.5778 33.7725 51.0241C37.2265 54.4704 42.1517 56.1935 48.548 56.1935C54.9443 56.1935 59.8375 54.4704 63.2275 51.0241C66.6815 47.5778 68.4085 42.9828 68.4085 37.239C68.4085 31.2399 66.7135 26.6129 63.3234 23.3581C59.9974 20.1033 55.0722 18.4759 48.548 18.4759ZM48.548 120.524C56.2875 120.524 62.332 118.418 66.6815 114.206C71.031 109.93 73.2057 104.122 73.2057 96.7831C73.2057 89.8905 71.031 84.4339 66.6815 80.4132C62.332 76.3287 56.2875 74.2865 48.548 74.2865C41.0003 74.2865 34.9558 76.2968 30.4144 80.3175C25.937 84.2743 23.6983 89.7629 23.6983 96.7831C23.6983 104.122 25.905 109.93 30.3185 114.206C34.7319 118.418 40.8084 120.524 48.548 120.524Z"
        fill="#FF252D"
      />
    </svg>
  )
}

export default SVG
