import { IconProps } from '@/assets/Icons/IconProps';

const SVG = ({ fill = 'none', viewBox = '0 0 43.13 43.13' }: IconProps) => {
  return (
    <svg viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
      <rect x="0.693359" width="43.1324" height="43.1324" rx="7.52021" fill="#252525" />
      <path
        d="M35.3071 15.4665C34.9925 14.0942 33.8748 13.0817 32.5293 12.9307C29.3431 12.5735 26.1181 12.5716 22.908 12.5735C19.6973 12.5716 16.4718 12.5735 13.2856 12.9307C11.9409 13.0817 10.824 14.0942 10.5094 15.4665C10.0617 17.4209 10.0566 19.5543 10.0566 21.5664C10.0566 23.5786 10.0566 25.7117 10.5044 27.6658C10.8184 29.0379 11.9356 30.0504 13.2811 30.2017C16.4671 30.5591 19.6923 30.561 22.9029 30.5591C26.1141 30.561 29.3386 30.5591 32.5243 30.2017C33.869 30.0506 34.987 29.0382 35.3015 27.6658C35.7495 25.7114 35.7511 23.5783 35.7511 21.5664C35.7511 19.5543 35.7548 17.4209 35.3071 15.4665ZM19.5881 25.5716C19.5881 22.7172 19.5881 19.8926 19.5881 17.0378C22.3112 18.4648 25.0163 19.8822 27.7567 21.3179C25.0248 22.7409 22.3184 24.1497 19.5881 25.5716Z"
        fill="white"
      />
    </svg>
  );
};

export default SVG;
