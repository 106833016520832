import { IconProps } from '@/assets/Icons/IconProps';

const SecondIcon = ({ isMobile }: IconProps) => (
  <svg width={isMobile ? 14 : 24} height={isMobile ? 14 : 22} fill="none">
    <path
      d={
        isMobile
          ? 'M12.2557 9.48328H1.22557V2.12987H12.2557M12.2557 0.904297H1.22557C0.545378 0.904297 0 1.44967 0 2.12987V9.48328C0 9.80832 0.129122 10.12 0.358961 10.3499C0.588799 10.5797 0.900528 10.7088 1.22557 10.7088H5.51506V11.9344H4.28949V13.16H9.19176V11.9344H7.9662V10.7088H12.2557C12.5807 10.7088 12.8925 10.5797 13.1223 10.3499C13.3521 10.12 13.4813 9.80832 13.4813 9.48328V2.12987C13.4813 1.44967 12.9297 0.904297 12.2557 0.904297Z'
          : 'M21.236 14.866H2.124V2.123h19.112m0-2.124H2.124A2.116 2.116 0 0 0 0 2.124v12.742a2.124 2.124 0 0 0 2.124 2.123h7.432v2.124H7.433v2.123h8.494v-2.123h-2.123v-2.124h7.432a2.123 2.123 0 0 0 2.124-2.123V2.123A2.123 2.123 0 0 0 21.236 0Z'
      }
      fill="#5B5B5B"
    />
  </svg>
);

export default SecondIcon;
