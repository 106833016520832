import { IconProps } from '@/assets/Icons/IconProps'

const SVG = ({
  fill = 'none',
  width = '24',
  height = '24',
  viewBox = '0 0 24 24',
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 2L12 12M12 12L2 22M12 12L22 2M12 12L22 22"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SVG
