import { IconProps } from '@/assets/Icons/IconProps';

const WidthIcon = ({ isMobile }: IconProps) => (
  <svg width={isMobile ? 15 : 24} height={isMobile ? 11 : 17} fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d={
        isMobile
          ? 'm9.238 1.328-.446.446 1.534 1.534 1.533 1.534H3.156l1.529-1.534 1.528-1.534-.446-.446-.446-.446-2.3 2.3-2.298 2.3L3.022 7.78l2.3 2.3.445-.446.446-.446L4.68 7.654 3.145 6.12h8.704L10.32 7.654 8.792 9.188l.446.446.446.446 2.299-2.3 2.3-2.299-2.3-2.3-2.3-2.299-.445.446Z'
          : 'm15.007 1.552-.773.773 2.658 2.658L19.55 7.64H4.469l2.648-2.657 2.649-2.658-.773-.773L8.221.78 4.236 4.764.252 8.748l3.984 3.984 3.985 3.985.772-.773.773-.773-2.658-2.657L4.45 9.856h15.081l-2.648 2.658-2.649 2.657.773.773.773.773 3.984-3.985 3.984-3.984-3.984-3.984L15.78.779l-.773.773Z'
      }
      fill="#5B5B5B"
    />
  </svg>
);

export default WidthIcon;
