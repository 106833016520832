const MapMark = () => {
  return (
    <svg viewBox="0 0 99 127" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M49.5 126.944C49.5 126.944 99 76.8381 99 49.5C99 22.1619 76.8381 0 49.5 0C22.1619 0 0 22.1619 0 49.5C0 76.8381 49.5 126.944 49.5 126.944ZM49.5002 63.871C60.9645 63.871 70.2582 54.5773 70.2582 43.113C70.2582 31.6486 60.9645 22.3549 49.5002 22.3549C38.0358 22.3549 28.7421 31.6486 28.7421 43.113C28.7421 54.5773 38.0358 63.871 49.5002 63.871Z"
        fill="#0601FF"
      />
    </svg>
  );
};

export default MapMark;
