export { default as Logotype } from './Logo';
export { default as SearchIcon } from './Search';
export { default as Eight } from './Eight';
export { default as CircleArrow } from './CircleArrow';
export { default as Star } from './Star';
export { default as Tag360 } from './Tag360';
export { default as Age } from './Age';
export { default as Close } from './Close';
export { default as AccordeonArrow } from './AccordeonArrow';
export { default as ShowedPassword } from './ShowedPassword';
export { default as UnshowedPassword } from './UnshowedPassword';
export { default as Sort } from './Sort';
export { default as GamesSearch } from './GamesSearch';
export { default as CompareIcon } from './CompareIcon';
export { default as Pack } from './Pack';
export { default as SpecificationsFirstIcon } from './MachinePage/specifications/FirstIcon';
export { default as SpecificationsSecondIcon } from './MachinePage/specifications/SecondIcon';
export { default as SpecificationsThirdIcon } from './MachinePage/specifications/ThirdIcon';
export { default as SpecificationsFourthIcon } from './MachinePage/specifications/FourthIcon';
export { default as SpecificationsFifthIcon } from './MachinePage/specifications/FifthIcon';
export { default as SpecificationsSixthIcon } from './MachinePage/specifications/SixthIcon';
export { default as SpecificationsSeventhIcon } from './MachinePage/specifications/SeventhIcon';
export { default as SpecificationsEighthIcon } from './MachinePage/specifications/EighthIcon';
export { default as SpecificationsNinthIcon } from './MachinePage/specifications/NinthIcon';
export { default as BenefitsFirstIcon } from './MachinePage/benefits/FirstIcon';
export { default as BenefitsSecondIcon } from './MachinePage/benefits/SecondIcon';
export { default as BenefitsThirdIcon } from './MachinePage/benefits/ThirdIcon';
export { default as BenefitsFourthIcon } from './MachinePage/benefits/FourthIcon';
export { default as BenefitsFifthIcon } from './MachinePage/benefits/FifthIcon';
export { default as BenefitsSixthIcon } from './MachinePage/benefits/SixthIcon';
export { default as BenefitsSeventhIcon } from './MachinePage/benefits/SeventhIcon';
export { default as DepthIcon } from './MachinePage/dimensions/DepthIcon';
export { default as HeightIcon } from './MachinePage/dimensions/HeightIcon';
export { default as WidthIcon } from './MachinePage/dimensions/WidthIcon';
export { default as WeightIcon } from './MachinePage/dimensions/WeightIcon';
export { default as OptionalIcon } from './MachinePage/OptionalIcon';
export { default as DownloadIcon } from './Download';
export { default as Mail } from './Mail';
export { default as ArrowLeft } from './ArrowLeft';
export { default as CrossIcon } from './Cross';
export { default as SwiperArrow } from './SwiperArrow';
export { default as MapMark } from './MapMark';

export { default as Linkedin } from './socials/Linkedin';
export { default as Facebook } from './socials/Facebook';
export { default as Twitter } from './socials/Twitter';
export { default as Youtube } from './socials/Youtube';
export { default as Instagram } from './socials/Instagram';
export { default as Skype } from './socials/Skype';
export { default as SkypeSolid } from './socials/SkypeSolid';
export { default as PlayButtonIcon } from './PlayButton';
export { default as SecondSwiperArrowIcon } from './SecondSwiperArrow';
