import { IconProps } from './IconProps';

const AccordeonArrow = ({ stroke }: IconProps) => {
  return (
    <svg viewBox="0 0 17 9" fill="none">
      <path
        stroke={stroke ?? 'currentColor'}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M16 1 8.5 8 1 1"
      />
    </svg>
  );
};

export default AccordeonArrow;
