import { IconProps } from '@/assets/Icons/IconProps';

const EighthIcon = ({ isMobile }: IconProps) => (
  <svg width={isMobile ? 12 : 20} height={isMobile ? 12 : 20} fill="none">
    <path
      d={
        isMobile
          ? 'M2.727 3.727c.122 0 .24.018.351.053.42-.472.887-.9 1.392-1.28a1.191 1.191 0 0 1 .03-.656A8.782 8.782 0 0 0 2.663.836 5.638 5.638 0 0 0 1.09 2.287c.316.56.692 1.083 1.12 1.559.156-.076.331-.119.517-.119ZM1.534 4.92c0-.167.035-.327.097-.47A9.698 9.698 0 0 1 .597 3.09 5.587 5.587 0 0 0 0 5.608c-.001 1.01.272 2.001.79 2.868.176-.991.51-1.948.987-2.835a1.187 1.187 0 0 1-.243-.72ZM5.63 1.03c.315 0 .6.122.814.322a9.496 9.496 0 0 1 1.96-.605A5.58 5.58 0 0 0 5.608 0c-.674 0-1.342.121-1.973.358.486.24.95.52 1.388.839a1.18 1.18 0 0 1 .607-.167Zm1.783 5.619c.074-.154.18-.29.31-.4a8.738 8.738 0 0 0-1.628-2.928 1.192 1.192 0 0 1-1.159-.128A8.743 8.743 0 0 0 3.74 4.292a1.176 1.176 0 0 1 .126.985A8.687 8.687 0 0 0 7.413 6.65ZM8.961 8.26a9.674 9.674 0 0 1-.05 1.879 5.617 5.617 0 0 0 2.04-2.828c-.437.106-.88.18-1.329.222a1.193 1.193 0 0 1-.661.727Zm-1.827-.809A9.535 9.535 0 0 1 3.35 5.937c-.26.16-.57.213-.868.151a8.667 8.667 0 0 0-.974 3.343 5.61 5.61 0 0 0 1.917 1.344 9.547 9.547 0 0 1 3.71-3.324Zm2.238-6a8.714 8.714 0 0 0-2.555.651c.022.222-.018.445-.117.644a9.558 9.558 0 0 1 1.81 3.225 1.195 1.195 0 0 1 1.079.729 8.677 8.677 0 0 0 1.571-.308 5.594 5.594 0 0 0-1.788-4.94ZM7.735 8.088a8.711 8.711 0 0 0-3.489 2.96 5.597 5.597 0 0 0 3.728-.356 8.71 8.71 0 0 0 .15-2.392 1.172 1.172 0 0 1-.39-.212Z'
          : 'M4.726 6.459c.21 0 .416.03.608.09a16.66 16.66 0 0 1 2.411-2.217c-.09-.377-.072-.77.05-1.137a15.217 15.217 0 0 0-3.18-1.747A9.77 9.77 0 0 0 1.89 3.963c.548.972 1.2 1.877 1.94 2.7.271-.13.574-.204.897-.204ZM2.657 8.526c0-.29.062-.567.168-.817a16.805 16.805 0 0 1-1.79-2.354A9.68 9.68 0 0 0 0 9.718a9.675 9.675 0 0 0 1.368 4.97 16.492 16.492 0 0 1 1.71-4.914 2.056 2.056 0 0 1-.42-1.248Zm7.098-6.742c.546 0 1.04.212 1.411.559 1.091-.471 2.23-.822 3.396-1.048A9.668 9.668 0 0 0 9.717 0C8.55 0 7.392.21 6.3.621c.841.416 1.646.901 2.405 1.452.309-.183.667-.29 1.051-.29Zm3.09 9.737c.128-.267.311-.503.538-.693a15.143 15.143 0 0 0-2.822-5.073 2.066 2.066 0 0 1-2.008-.223A15.15 15.15 0 0 0 6.48 7.437a2.038 2.038 0 0 1 .218 1.708 15.055 15.055 0 0 0 6.149 2.376Zm2.682 2.792a16.762 16.762 0 0 1-.085 3.255 9.735 9.735 0 0 0 3.535-4.9c-.758.184-1.527.313-2.304.386a2.068 2.068 0 0 1-1.146 1.259Zm-3.165-1.402a16.522 16.522 0 0 1-6.56-2.623c-.448.276-.986.37-1.502.261a15.018 15.018 0 0 0-1.689 5.793 9.722 9.722 0 0 0 3.322 2.329 16.543 16.543 0 0 1 6.429-5.76ZM16.24 2.515a15.1 15.1 0 0 0-4.428 1.128c.038.383-.032.77-.202 1.115 1.399 1.641 2.464 3.54 3.136 5.589a2.071 2.071 0 0 1 1.87 1.262 15.035 15.035 0 0 0 2.722-.533 9.694 9.694 0 0 0-3.098-8.561Zm-2.838 11.5a15.094 15.094 0 0 0-6.044 5.129c.771.193 1.564.29 2.36.29a9.68 9.68 0 0 0 4.1-.907c.2-.98.306-1.993.306-3.03 0-.375-.017-.747-.046-1.114a2.031 2.031 0 0 1-.676-.368Z'
      }
      fill="#5B5B5B"
    />
  </svg>
);

export default EighthIcon;
