import { IconProps } from '@/assets/Icons/IconProps';

const FourthIcon = ({ isMobile }: IconProps) => (
  <div
    style={{
      border: `${isMobile ? '1px' : '2px'} solid #666`,
      borderRadius: '50%',
      width: isMobile ? '14px' : '26px',
      height: isMobile ? '14px' : '26px',
    }}
  >
    <svg
      width={isMobile ? 14 : 26}
      height={isMobile ? 14 : 26}
      fill="none"
      style={{
        transform: isMobile ? 'translate(-1.3px, -6.3px)' : 'translate(-2px, -2px)',
      }}
    >
      <path
        d={
          isMobile
            ? 'M8.057 8.44v-.553h-2.39l1.284-2.14v1.37h2.39L8.057 9.258V8.44Z'
            : 'M13.961 14.625v-.958H9.82l2.225-3.709v2.375h4.141l-2.225 3.709v-1.417Z'
        }
        fill="#666"
        stroke="#5B5B5B"
        strokeWidth={isMobile ? 1.106 : 1.916}
      />
    </svg>
  </div>
);

export default FourthIcon;
