import { IconProps } from './IconProps';

const SVG = ({ isMobile }: IconProps) => {
  return (
    <svg width={!isMobile ? 25 : 16} height={!isMobile ? 26 : 16} fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d={
          isMobile
            ? 'M6.907.11A7.722 7.722 0 0 0 .673 5.715C.525 6.26.5 6.538.5 7.598s.025 1.338.173 1.884c.644 2.384 2.437 4.364 4.705 5.196.986.362 1.33.415 2.66.412 1.173-.004 1.341-.022 1.962-.209 2.656-.801 4.49-2.633 5.291-5.286.188-.62.205-.788.209-1.96.004-1.328-.05-1.672-.412-2.658C14.254 2.71 12.25.9 9.887.283 9.402.156 8.987.11 8.189.095 7.61.084 7.032.091 6.907.111ZM9.15 1.45c2.381.455 4.222 2.156 4.898 4.528.243.852.246 2.378.007 3.214-.231.806-.477 1.333-.926 1.981-.845 1.222-2.011 2.038-3.532 2.473-.837.24-2.365.236-3.218-.007-1.964-.559-3.49-1.92-4.194-3.737C.592 5.786 3.545 1.397 7.938 1.35c.36-.004.904.04 1.212.1ZM4.568 4.52c-.39.213-.464.699-.154 1.009l.185.185h6.804l.185-.185c.31-.311.23-.797-.169-1.009-.224-.12-6.632-.12-6.85 0Zm.935 2.513a.63.63 0 0 0 .057 1.14c.36.15 4.523.15 4.882 0a.626.626 0 0 0 .041-1.144c-.307-.159-4.687-.156-4.98.004ZM6.3 9.667c-.305.305-.244.797.122.986.274.141 2.853.16 3.111.023.399-.213.48-.698.169-1.009-.185-.184-.193-.185-1.701-.185-1.509 0-1.517 0-1.7.185Z'
            : 'M10.678.535A12.87 12.87 0 0 0 .288 9.873c-.246.91-.288 1.374-.288 3.14 0 1.767.042 2.23.288 3.14 1.073 3.973 4.062 7.274 7.842 8.661 1.644.603 2.217.692 4.434.686 1.954-.006 2.235-.036 3.27-.348 4.426-1.335 7.481-4.387 8.818-8.81.312-1.033.342-1.314.348-3.266.006-2.215-.083-2.788-.686-4.43-1.39-3.781-4.732-6.797-8.67-7.825-.807-.21-1.499-.287-2.828-.312-.968-.019-1.93-.007-2.138.026Zm3.738 2.23c3.97.76 7.037 3.595 8.164 7.548.406 1.42.41 3.964.011 5.358-.384 1.342-.795 2.22-1.543 3.302-1.408 2.035-3.352 3.396-5.886 4.12-1.396.4-3.942.395-5.364-.01-3.273-.932-5.817-3.2-6.99-6.23C.153 9.994 5.074 2.679 12.396 2.6c.6-.007 1.508.068 2.02.166ZM6.781 7.884c-.65.355-.774 1.166-.257 1.682l.308.308H18.171l.308-.308c.52-.518.384-1.328-.28-1.681-.375-.2-11.054-.2-11.418-.001Zm1.557 4.188a1.049 1.049 0 0 0 .095 1.9c.599.25 7.538.25 8.137 0 .84-.35.881-1.486.07-1.905-.513-.265-7.813-.26-8.302.005Zm1.329 4.391c-.509.508-.407 1.328.203 1.643.457.236 4.755.267 5.186.038.664-.354.799-1.163.28-1.68-.307-.308-.32-.31-2.835-.31-2.514 0-2.527.002-2.834.31Z'
        }
        fill="currentColor"
      />
    </svg>
  );
};

export default SVG;
