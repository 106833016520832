import { IconProps } from '@/assets/Icons/IconProps';

const SixthIcon = ({ isMobile }: IconProps) => (
  <svg width={isMobile ? 18 : 31} height={isMobile ? 18 : 31} fill="none">
    <path
      d={
        isMobile
          ? 'M12.754 9.001a.75.75 0 0 1 .75.75v6.75h-1.5v-6h-6v6h-1.5v-6.75a.75.75 0 0 1 .75-.75h7.5Zm-3.75 3v1.5h-1.5v-1.5h1.5Zm0-7.5a4.5 4.5 0 0 1 4.026 2.487l-1.343.671a3 3 0 0 0-5.368 0l-1.342-.671a4.5 4.5 0 0 1 4.026-2.487Zm0-3a7.5 7.5 0 0 1 6.709 4.145l-1.342.671a6 6 0 0 0-8.523-2.42 6 6 0 0 0-2.212 2.42l-1.343-.67A7.5 7.5 0 0 1 9.003 1.5Z'
          : 'M21.794 15.38a1.282 1.282 0 0 1 1.282 1.282v11.535h-2.563V17.944H10.259v10.253H7.696V16.662a1.282 1.282 0 0 1 1.282-1.282h12.816Zm-6.408 5.127v2.563h-2.563v-2.563h2.563Zm0-12.817a7.69 7.69 0 0 1 6.88 4.25l-2.294 1.147a5.127 5.127 0 0 0-9.173 0L8.506 11.94a7.69 7.69 0 0 1 6.88-4.25Zm0-5.127a12.816 12.816 0 0 1 11.466 7.084l-2.293 1.147A10.253 10.253 0 0 0 9.994 6.657a10.253 10.253 0 0 0-3.781 4.137L3.919 9.647a12.817 12.817 0 0 1 11.467-7.084Z'
      }
      fill="#5B5B5B"
    />
  </svg>
);

export default SixthIcon;
