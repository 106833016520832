import { IconProps } from '@/assets/Icons/IconProps';

const HeightIcon = ({ isMobile }: IconProps) => (
  <svg width={isMobile ? 10 : 17} height={isMobile ? 14 : 24} fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d={
        isMobile
          ? 'm1.204 5.293.446.446 1.534-1.534 1.534-1.534v8.704L3.184 9.846 1.65 8.318l-.446.446-.446.445 2.3 2.3 2.3 2.3 2.298-2.3 2.3-2.3-.446-.445-.446-.446L7.53 9.85l-1.534 1.534V2.682L7.53 4.21 9.064 5.74l.446-.446.446-.446-2.3-2.3L5.357.249l-2.3 2.3-2.299 2.3.446.445Z'
          : 'm1.552 8.741.773.773 2.658-2.658L7.64 4.198V19.28l-2.657-2.648-2.658-2.649-.773.773-.773.772 3.985 3.985 3.984 3.984 3.984-3.984 3.985-3.985-.773-.772-.773-.773-2.657 2.658-2.658 2.658V4.217l2.658 2.648 2.657 2.649.773-.773.773-.772-3.985-3.985L8.748 0 4.764 3.984.779 7.97l.773.772Z'
      }
      fill="#5B5B5B"
    />
  </svg>
);

export default HeightIcon;
