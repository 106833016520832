import { IconProps } from '@/assets/Icons/IconProps';

const FirstIcon = ({ isMobile }: IconProps) => (
  <svg width={isMobile ? 15 : 24} height={isMobile ? 11 : 18} fill="none">
    <path
      d={
        isMobile
          ? 'M1 3.612v5.223c0 .346.146.678.405.923.26.245.611.383.978.383h9.684c.367 0 .719-.138.978-.383a1.27 1.27 0 0 0 .405-.923V3.612M1 3.612V2.306c0-.347.146-.679.405-.924.26-.244.611-.382.978-.382h9.684c.367 0 .719.138.978.382.26.245.405.577.405.924v1.306M1 3.612h12.45M3.767 6.223h3.458'
          : 'M1 5.525v9.05c0 .601.253 1.177.702 1.6a2.47 2.47 0 0 0 1.695.663h16.78a2.47 2.47 0 0 0 1.694-.662 2.2 2.2 0 0 0 .702-1.6v-9.05M1 5.525V3.262c0-.6.253-1.176.702-1.6A2.471 2.471 0 0 1 3.397 1h16.78c.635 0 1.245.238 1.694.663.45.424.702 1 .702 1.6v2.262M1 5.525h21.573M5.794 10.051h5.993'
      }
      stroke="#5B5B5B"
      strokeWidth={isMobile ? 1.154 : 2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default FirstIcon;
