import { IconProps } from './IconProps';

const Cross = ({ stroke }: IconProps) => {
  return (
    <svg viewBox="0 0 35 35" fill="none">
      <path
        opacity={0.5}
        d="m2 2 15.5 15.5m0 0L2 33m15.5-15.5L33 2M17.5 17.5 33 33"
        stroke={stroke ?? 'currentColor'}
        strokeWidth={3}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Cross;
