const SVG = () => {
  return (
    <svg width={40} height={44} fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.16 13.554c-.053.044-.095.38-.095.746v.666H15.83c-3.122.002-3.258.011-3.902.271-.801.325-1.292.75-1.656 1.44-.263.496-.272.718-.272 6.843s.009 6.347.272 6.843c.364.69.855 1.115 1.656 1.44.644.26.78.27 3.896.27l3.228.001.04.713.04.713h1.733V13.54l-.805-.033c-.444-.019-.849.003-.9.047Zm3.266 2.124.04.713 2.401.057c2.592.062 2.86.119 3.2.682.302.5.302 12.28 0 12.78-.34.563-.608.62-3.2.681l-2.4.058-.04.712-.04.713h2.507c2.358-.002 2.547-.018 3.175-.271.801-.325 1.291-.75 1.656-1.44.263-.496.272-.72.273-6.843L30 17.19l-.315-.57c-.38-.686-.877-1.105-1.683-1.417-.535-.207-.874-.233-3.108-.235l-2.507-.002.04.713Z"
        fill="#B2B2B2"
      />
    </svg>
  );
};

export default SVG;
