import { IconProps } from '@/assets/Icons/IconProps';

const SixthIcon = ({ isMobile }: IconProps) => (
  <svg width={isMobile ? 12 : 21} height={isMobile ? 11 : 19} fill="none">
    <path
      d={
        isMobile
          ? 'm1.694 1.702.82.82a4.652 4.652 0 0 1 6.582 0l.82-.82a5.815 5.815 0 0 0-8.222 0Zm1.645 1.646.82.82a2.326 2.326 0 0 1 3.291 0l.82-.82a3.489 3.489 0 0 0-4.93 0Zm1.745 3.634a2.617 2.617 0 1 0 0 1.745h2.465v1.744h1.744V8.727h2.326V6.982M2.607 8.727a.872.872 0 1 1 0-1.745.872.872 0 0 1 0 1.745Z'
          : 'm2.935 2.95 1.42 1.42a8.06 8.06 0 0 1 11.406 0l1.42-1.42a10.076 10.076 0 0 0-14.246 0Zm2.851 2.851 1.42 1.421a4.03 4.03 0 0 1 5.703 0l1.421-1.42a6.045 6.045 0 0 0-8.544 0ZM8.81 12.1a4.534 4.534 0 1 0 0 3.022h4.272v3.023h3.023v-3.023h4.03V12.1M4.517 15.12a1.511 1.511 0 1 1 0-3.022 1.511 1.511 0 0 1 0 3.022Z'
      }
      fill="#5B5B5B"
    />
  </svg>
);

export default SixthIcon;
