import { IconProps } from '@/assets/Icons/IconProps';

const SVG = ({ fill = 'none', width = '34', height = '25', viewBox = '0 0 34 25' }: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.64274 0C1.62058 0 0 1.75694 0 3.84119V21.1457C0 23.23 1.62065 25 3.64274 25H30.3573C32.3794 25 34 23.23 34 21.1457V3.84119C34 1.75694 32.3793 0 30.3573 0H3.64274ZM3.81977 2.50018H30.1931L17.7213 14.6878C17.2676 15.1313 16.7596 15.1319 16.3045 14.6878L3.81977 2.50018ZM2.42839 4.58354L10.7008 12.6565L2.42839 21.1462V4.58354ZM31.5715 4.58354V21.1462L23.3244 12.6432L31.5715 4.58354ZM21.5789 14.3627L29.4588 22.5012H4.55341L12.4593 14.3761L14.6351 16.4983C15.9539 17.785 18.0726 17.787 19.3912 16.4983L21.5789 14.3627Z"
        fill="white"
      />
    </svg>
  );
};

export default SVG;
