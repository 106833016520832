const SVG = () => {
  return (
    <svg width="29" height="24" viewBox="0 0 29 24" fill="none">
      <path d="M12.9497 2L2.94971 12L12.9497 22" stroke="white" strokeWidth="3.5" />
      <path d="M4.12622 12H28.2439" stroke="white" strokeWidth="3.5" />
    </svg>
  );
};

export default SVG;
