import { IconProps } from './IconProps';

const SVG = ({ isMobile }: IconProps) => {
  return (
    <svg width={isMobile ? 18 : 25} height={isMobile ? 17 : 25} fill="none">
      <path
        d={
          isMobile
            ? 'm14.194 8.966-1.472-1.472-2.38 2.385V.344h-2.08v9.535L5.877 7.494l-1.47 1.472 4.895 4.893 4.892-4.893Zm3.425 5.932H.985v2.08h16.634v-2.08Z'
            : 'm19.856 13.214-2.148-2.148-3.474 3.48V.633H11.2v13.913l-3.48-3.48-2.146 2.148 7.143 7.138 7.139-7.138Zm4.996 8.655H.582v3.034h24.27v-3.034Z'
        }
        fill="#5B5B5B"
      />
    </svg>
  );
};

export default SVG;
