import { IconProps } from '@/assets/Icons/IconProps';

const DepthIcon = ({ isMobile }: IconProps) => (
  <svg width={isMobile ? 11 : 16} height={isMobile ? 11 : 17} fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d={
        isMobile
          ? 'M3.856 1.262v.646h4.178L5.07 5.056 2.107 8.205l-.004-2.216L2.1 3.773H.885V10.428h6.263v-1.29H2.971l2.963-3.149 2.963-3.148.004 2.215.004 2.216h1.214V.617H3.856v.645Z'
          : 'M5.147 1.118v1.118h7.24L7.252 7.692l-5.135 5.455-.006-3.839-.007-3.839H0V17h10.853v-2.236h-7.24l5.135-5.456 5.135-5.455.006 3.839.007 3.839H16V0H5.147v1.118Z'
      }
      fill="#5B5B5B"
    />
  </svg>
);

export default DepthIcon;
