import { IconProps } from '@/assets/Icons/IconProps';

const ThirdIcon = ({ isMobile }: IconProps) => (
  <svg width={isMobile ? 19 : 32} height={isMobile ? 19 : 32} fill="none">
    <path
      d={
        isMobile
          ? 'M8.591 1.718a.573.573 0 1 1 1.146 0v1.719a.573.573 0 1 1-1.146 0V1.718ZM12.828 5.503a.573.573 0 0 1 0-.81l1.198-1.198a.573.573 0 1 1 .81.81l-1.199 1.198a.573.573 0 0 1-.81 0ZM14.32 9.165c0-.317.256-.573.572-.573h1.719a.573.573 0 0 1 0 1.145h-1.719a.573.573 0 0 1-.572-.572ZM12.828 13.636a.573.573 0 1 1 .81-.81l1.197 1.199a.573.573 0 1 1-.81.81l-1.197-1.199ZM8.591 14.892a.573.573 0 0 1 1.146 0v1.718a.573.573 0 0 1-1.146 0v-1.718ZM3.494 14.835a.573.573 0 0 1 0-.81l1.198-1.198a.573.573 0 1 1 .81.81l-1.198 1.198a.573.573 0 0 1-.81 0ZM1.145 9.165c0-.317.257-.573.573-.573h1.718a.573.573 0 1 1 0 1.145H1.718a.573.573 0 0 1-.573-.572ZM3.494 4.304a.573.573 0 0 1 .81-.81l1.198 1.199a.573.573 0 1 1-.81.81L3.494 4.304ZM9.165 6.873a2.291 2.291 0 1 1 0 4.583 2.291 2.291 0 0 1 0-4.583Zm0-1.145a3.437 3.437 0 1 0 0 6.873 3.437 3.437 0 0 0 0-6.873Z'
          : 'M14.887 2.978a.993.993 0 0 1 1.985 0v2.977a.992.992 0 1 1-1.985 0V2.978ZM22.227 9.535a.993.993 0 0 1 0-1.404l2.076-2.076a.992.992 0 0 1 1.404 1.404L23.63 9.535a.992.992 0 0 1-1.404 0ZM24.813 15.88c0-.548.444-.992.992-.992h2.977a.993.993 0 0 1 0 1.985h-2.977a.993.993 0 0 1-.992-.993ZM22.227 23.628a.993.993 0 0 1 1.404-1.403l2.076 2.076a.993.993 0 0 1-1.404 1.404l-2.076-2.076ZM14.887 25.805a.993.993 0 0 1 1.985 0v2.977a.992.992 0 1 1-1.985 0v-2.977ZM6.054 25.705a.992.992 0 0 1 0-1.404l2.076-2.076a.993.993 0 0 1 1.403 1.404l-2.076 2.076a.993.993 0 0 1-1.403 0ZM1.984 15.88c0-.548.445-.992.993-.992h2.977a.993.993 0 0 1 0 1.985H2.977a.993.993 0 0 1-.993-.993ZM6.054 7.459a.992.992 0 0 1 1.403-1.404l2.076 2.076A.992.992 0 0 1 8.13 9.535L6.054 7.459ZM15.88 11.91a3.97 3.97 0 1 1 .001 7.939 3.97 3.97 0 0 1 0-7.94Zm0-1.985a5.955 5.955 0 1 0 .001 11.91 5.955 5.955 0 0 0 0-11.91Z'
      }
      fill="#5B5B5B"
    />
  </svg>
);

export default ThirdIcon;
