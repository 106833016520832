import { IconProps } from '@/assets/Icons/IconProps';

const FifthIcon = ({ isMobile }: IconProps) => (
  <svg width={isMobile ? 17 : 27} height={isMobile ? 11 : 18} fill="none">
    <path
      d={
        isMobile
          ? 'm11.184 5.52 4.244-3.013v6.027l-4.244-3.013Zm0 0V2.508c0-.832-.76-1.507-1.697-1.507h-6.79C1.76 1 1 1.675 1 2.507v6.027c0 .833.76 1.507 1.697 1.507h6.79c.937 0 1.697-.674 1.697-1.507V5.521Z'
          : 'M18.647 8.833 26 3.611v10.444l-7.353-5.222Zm0 0V3.611C18.647 2.17 17.33 1 15.706 1H3.94C2.317 1 1 2.169 1 3.611v10.444c0 1.442 1.317 2.611 2.941 2.611h11.765c1.624 0 2.941-1.169 2.941-2.61V8.832Z'
      }
      stroke="#5B5B5B"
      strokeWidth={isMobile ? 1.154 : 2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default FifthIcon;
