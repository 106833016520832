import { IconProps } from '@/assets/Icons/IconProps';

const WeightIcon = ({ isMobile }: IconProps) => (
  <svg width={isMobile ? 13 : 22} height={isMobile ? 14 : 22} fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d={
        isMobile
          ? 'M5.895.769c-.361.076-.619.183-.92.382-.212.14-.56.49-.692.697-.442.69-.533 1.44-.275 2.266.034.108.077.228.096.267.02.04.042.093.05.118l.014.046H1.452l-.011.057C1.41 4.752 0 13.36 0 13.392c0 .034.316.036 6.348.036 5.929 0 6.348-.002 6.348-.035 0-.03-1.412-8.65-1.44-8.791l-.011-.057H9.888c-.746 0-1.357-.007-1.357-.015 0-.009.037-.102.082-.209.163-.384.236-.798.212-1.193C8.75 1.936 7.918.98 6.764.761a2.822 2.822 0 0 0-.869.008Zm.786 1.244c.412.124.743.451.87.86a1.57 1.57 0 0 1-.004.789c-.174.54-.71.912-1.27.876a1.26 1.26 0 0 1-1.145-.936 1.544 1.544 0 0 1-.011-.656 1.29 1.29 0 0 1 1.031-.966c.129-.024.394-.007.529.033Zm3.48 3.794.503 3.152c.277 1.728.5 3.145.497 3.149-.004.004-2.185.005-4.846.002l-4.839-.005.53-3.139.529-3.154c0-.017 7.626-.022 7.626-.005Z'
          : 'M10.216.065A4.236 4.236 0 0 0 8.62.727c-.368.243-.97.85-1.2 1.208-.766 1.195-.923 2.495-.476 3.927.058.186.134.394.167.462.034.068.072.16.086.204l.024.08H2.516l-.02.098C2.446 6.966 0 21.884 0 21.938 0 21.997.547 22 11 22c10.272 0 11-.004 11-.06 0-.052-2.447-14.988-2.496-15.234l-.02-.098h-2.35c-1.293 0-2.351-.012-2.351-.026 0-.015.064-.178.142-.362.281-.666.409-1.383.366-2.067C15.163 2.086 13.72.429 11.72.052c-.393-.074-1.119-.068-1.505.013Zm1.36 2.156c.715.215 1.288.781 1.508 1.49.114.368.111.999-.007 1.367-.301.937-1.231 1.58-2.199 1.519a2.184 2.184 0 0 1-1.985-1.623c-.087-.322-.096-.837-.02-1.136a2.235 2.235 0 0 1 1.788-1.674 2.56 2.56 0 0 1 .915.057Zm6.031 6.573c0 .01.392 2.468.872 5.463.48 2.994.866 5.45.86 5.456-.007.007-3.785.008-8.397.003l-8.384-.009.917-5.438c.504-2.992.917-5.451.917-5.466.001-.029 13.215-.038 13.215-.009Z'
      }
      fill="#5B5B5B"
    />
  </svg>
);

export default WeightIcon;
