const SVG = () => {
  return (
    <svg width={88} height={99} fill="none" viewBox="0 0 88 99">
      <path
        fill="currentColor"
        fillOpacity={1}
        d="M83.482 42.188c5.59 3.227 5.59 11.296 0 14.524L12.65 97.606C7.06 100.834.072 96.8.072 90.344V8.555C.072 2.1 7.06-1.935 12.65 1.293l70.832 40.895Z"
      />
    </svg>
  );
};

export default SVG;
