const SVG = ({ isMobile }: { isMobile?: boolean }) => {
  return (
    <svg width={!isMobile ? 33 : 23} height={!isMobile ? 21 : 13} fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d={
          isMobile
            ? 'M11.5 0C6.77 0 2.407 2.368.088 6.19a.588.588 0 0 0 0 .614C2.403 10.628 6.769 12.998 11.5 13c4.73-.002 9.095-2.37 11.412-6.193a.587.587 0 0 0 0-.614C20.596 2.37 16.231.003 11.501 0Zm0 1.255c4.116.001 7.918 1.997 10.04 5.245-2.122 3.249-5.924 5.244-10.039 5.246-4.117-.002-7.92-1.999-10.042-5.25C3.583 3.25 7.385 1.256 11.5 1.256Zm0 1.444c-2.257 0-4.101 1.71-4.101 3.801 0 2.092 1.844 3.802 4.1 3.802 2.258 0 4.102-1.71 4.102-3.802S13.757 2.7 11.5 2.7Zm0 1.254c1.526 0 2.748 1.133 2.748 2.547 0 1.414-1.222 2.547-2.748 2.547-1.526 0-2.748-1.133-2.748-2.547 0-1.414 1.222-2.547 2.748-2.547Z'
            : 'M16.501 0.000717614C9.71419 0.0010003 3.45152 3.82473 0.124503 9.99776H0.124774C-0.0415008 10.3058 -0.0415008 10.6823 0.124232 10.9907C3.44813 17.169 9.71149 20.9971 16.5007 21V20.9997H16.5018C23.2886 20.9972 29.5506 17.1712 32.8755 10.9962C33.0415 10.6881 33.0415 10.3116 32.8755 10.0036C29.5505 3.82864 23.2882 0.00361766 16.5018 0H16.501V0.000717614ZM16.5007 2.02661C22.406 2.02887 27.8607 5.25283 30.9048 10.5006C27.8601 15.7479 22.406 18.971 16.5014 18.9739C10.5942 18.9716 5.13798 15.7454 2.09382 10.4948C5.1404 5.24922 10.5954 2.02661 16.5 2.02661H16.5007ZM16.4999 4.35913C13.2616 4.35913 10.6155 7.12071 10.6155 10.5004C10.6155 13.8798 13.2616 16.6414 16.4999 16.6414C19.738 16.6414 22.384 13.8798 22.384 10.5004C22.384 7.12071 19.738 4.35913 16.4999 4.35913ZM16.4999 6.38502C18.6888 6.38502 20.4426 8.21534 20.4426 10.5001C20.4426 12.7846 18.6888 14.6149 16.4999 14.6149C14.3107 14.6149 12.5569 12.7846 12.5569 10.5001C12.5569 8.21534 14.3107 6.38502 16.4999 6.38502Z'
        }
        fill="#7D7D7D"
      />
    </svg>
  );
};

export default SVG;
