import { IconProps } from '@/assets/Icons/IconProps';

const SVG = ({ fill = 'none', viewBox = '0 0 43.13 43.13' }: IconProps) => {
  return (
    <svg viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
      <rect width="43.1306" height="43.1306" rx="7.52021" fill="#252525" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.6692 21.6851V31.7602H19.5006V21.6855H17.418V18.2135H19.5006V16.129C19.5006 13.2966 20.6766 11.6123 24.0177 11.6123H26.7993V15.0846H25.0606C23.76 15.0846 23.6739 15.5699 23.6739 16.4754L23.6692 18.2131H26.819L26.4504 21.6851H23.6692Z"
        fill="white"
      />
    </svg>
  );
};

export default SVG;
