import { IconProps } from '@/assets/Icons/IconProps';

const SVG = ({ fill = 'none', width = '23', height = '23', isMobile }: IconProps) => {
  return (
    <svg width={width} height={height} fill={fill} viewBox="0 0 23 23">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d={
          isMobile
            ? 'M3.525.148C1.807.613.421 2.117.076 3.89c-.222 1.144.053 2.52.69 3.451.305.444.309.465.287 1.457-.053 2.38.819 4.5 2.51 6.107 1.591 1.51 3.795 2.34 5.866 2.204.744-.048.79-.038 1.534.327 2.009.986 4.087.667 5.618-.864 1.57-1.57 1.875-4.012.726-5.832-.228-.36-.24-.443-.191-1.236.144-2.295-.784-4.615-2.497-6.242-1.606-1.525-3.592-2.278-5.853-2.218-.951.026-.973.021-1.398-.28C6.387.068 4.776-.191 3.525.148ZM6.197 1.56c.278.127.693.384.921.571l.416.34.746-.103c2.684-.373 5.556 1.173 6.802 3.66.593 1.185.76 2.02.702 3.519l-.047 1.203.312.409c.456.597.666 1.288.657 2.166-.006.653-.049.826-.36 1.457-.398.803-.858 1.257-1.689 1.663-.456.223-.65.26-1.393.26-.988.002-1.54-.167-2.204-.674l-.418-.319-.654.097c-1.279.19-2.631-.05-3.962-.701-1.057-.517-2.362-1.784-2.897-2.813-.657-1.263-.951-2.869-.751-4.102l.106-.651-.336-.41a5.542 5.542 0 0 1-.6-.978c-.222-.479-.264-.697-.265-1.383 0-.713.036-.893.295-1.438.485-1.024 1.43-1.796 2.468-2.015.608-.128 1.579-.02 2.15.242Zm1.235 2.158c-1.041.368-1.718.95-2.112 1.815-.294.643-.281 1.988.024 2.614.446.915 1.224 1.392 3.094 1.9 1.367.373 1.688.512 1.816.794.157.345-.021.592-.528.734-.808.226-2.054-.142-3.048-.901l-.429-.327-.835 1.001-.835 1.002.302.26c.552.477 1.466.983 2.245 1.243.679.227.922.257 2.064.261 1.256.004 1.313-.005 1.929-.297 1.245-.59 1.866-1.436 1.97-2.686.158-1.876-.893-2.945-3.612-3.674-1.269-.34-1.582-.522-1.582-.92 0-.762 1.77-.643 3.029.205.276.185.531.303.568.262.036-.042.36-.502.72-1.023.487-.708.63-.984.561-1.091-.141-.225-1.232-.85-1.888-1.083-.889-.315-2.685-.361-3.453-.09Z'
            : 'M4.504.189C2.31.784.538 2.705.097 4.971-.187 6.433.165 8.19.98 9.38c.388.567.393.593.365 1.861-.068 3.04 1.047 5.75 3.208 7.803 2.033 1.93 4.849 2.99 7.496 2.817.95-.062 1.01-.05 1.96.417 2.566 1.26 5.221.852 7.177-1.103 2.008-2.008 2.396-5.127.928-7.452-.291-.461-.307-.567-.244-1.58.185-2.932-1.001-5.896-3.19-7.976-2.052-1.949-4.59-2.91-7.479-2.834-1.215.033-1.244.027-1.786-.358C8.16.086 6.103-.244 4.505.19Zm3.414 1.805c.356.163.886.492 1.178.73l.53.436.954-.133c3.43-.477 7.1 1.498 8.69 4.678.759 1.513.972 2.581.898 4.495l-.06 1.537.4.523c.582.763.85 1.646.839 2.768-.008.834-.063 1.055-.461 1.861-.508 1.027-1.096 1.607-2.157 2.126-.583.285-.832.331-1.78.332-1.263.002-1.969-.214-2.816-.861l-.534-.408-.837.124c-1.634.241-3.362-.065-5.062-.897-1.351-.66-3.018-2.278-3.702-3.593-.84-1.615-1.216-3.666-.96-5.241l.135-.833-.428-.523a7.082 7.082 0 0 1-.767-1.25c-.284-.612-.337-.89-.339-1.768 0-.91.046-1.14.377-1.837.62-1.309 1.828-2.294 3.154-2.575.776-.164 2.017-.024 2.748.31Zm1.578 2.758c-1.33.47-2.194 1.213-2.699 2.32-.375.821-.359 2.54.032 3.34.57 1.168 1.563 1.778 3.953 2.428 1.747.475 2.156.654 2.32 1.014.2.44-.027.756-.675.938-1.032.289-2.624-.182-3.894-1.152l-.548-.418-1.067 1.28-1.067 1.28.386.333c.705.608 1.873 1.255 2.869 1.588.867.289 1.178.328 2.636.333 1.605.005 1.678-.006 2.466-.38 1.59-.753 2.383-1.834 2.517-3.431.201-2.398-1.141-3.764-4.615-4.696-1.622-.435-2.021-.667-2.021-1.175 0-.974 2.26-.821 3.869.262.353.237.68.388.726.334.046-.053.46-.64.92-1.306.623-.905.804-1.257.718-1.395-.182-.287-1.576-1.086-2.413-1.383-1.136-.402-3.431-.462-4.413-.114Z'
        }
        fill={fill}
      />
    </svg>
  );
};

export default SVG;
