import { IconProps } from '../IconProps';

const SVG = ({ fill = 'none' }: IconProps) => {
  return (
    <svg viewBox="0 0 75 75" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <rect x="0.219727" width="74.0874" height="74.0874" rx="12.9173" fill="#0041FF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.3706 29.7093V44.3787C19.3706 50.8109 24.6037 56.0439 31.036 56.0439H45.7053C52.1375 56.0439 57.3706 50.8109 57.3706 44.3787V29.7093C57.3706 23.277 52.1375 18.0439 45.7053 18.0439H31.036C24.6037 18.0439 19.3706 23.277 19.3706 29.7093ZM23.152 30.2291C23.152 25.5953 26.9219 21.8254 31.5558 21.8254H45.1856C49.8194 21.8254 53.5892 25.5953 53.5892 30.2291V43.8589C53.5892 48.4927 49.8194 52.2625 45.1856 52.2625H31.5558C26.9219 52.2625 23.152 48.4927 23.152 43.8589V30.2291ZM33.1598 37.0439C33.1598 39.9172 35.4974 42.2548 38.3706 42.2548C41.2439 42.2548 43.5814 39.9172 43.5814 37.0439C43.5814 34.1707 41.2439 31.8331 38.3706 31.8331C35.4974 31.8331 33.1598 34.1707 33.1598 37.0439ZM29.7152 37.0439C29.7152 32.2713 33.5979 28.3886 38.3706 28.3886C43.1433 28.3886 47.026 32.2713 47.026 37.0439C47.026 41.8166 43.1433 45.6993 38.3706 45.6993C33.5979 45.6993 29.7152 41.8166 29.7152 37.0439ZM48.1178 24.9363C49.3423 24.9363 50.3349 25.9289 50.3349 27.1534C50.3349 28.3779 49.3423 29.3705 48.1178 29.3705C46.8934 29.3705 45.9007 28.3779 45.9007 27.1534C45.9007 25.9289 46.8934 24.9363 48.1178 24.9363Z"
        fill="white"
      />
    </svg>
  );
};

export default SVG;
