import { IconProps } from '@/assets/Icons/IconProps';

const FirstIcon = ({ isMobile }: IconProps) => (
  <svg width={isMobile ? 13 : 21} height={isMobile ? 13 : 22} fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d={
        isMobile
          ? 'M0 1.07S1.95-.004 3.967 0v11.712c-.933.132-2.187.42-3.967.944V1.07Zm4.905 10.555a7.34 7.34 0 0 0-.36.021V.032c.12.014.24.032.36.056v11.537Zm1.154.09c.1.021.196.046.289.072V.692L6.335.683A3.481 3.481 0 0 0 6.06.51v11.206Zm2.453.774a7.385 7.385 0 0 1-.433-.103V1.436c.145.032.29.057.433.077v10.976Zm.577.097c.736.1 1.697.142 3.03.07V1.07a8 8 0 0 1-3.03.494v11.022Z'
          : 'M0 1.854S3.378-.007 6.875 0v20.294c-1.618.23-3.79.729-6.875 1.636V1.854Zm8.5 18.29c-.197.006-.405.018-.625.037V.055c.21.024.418.056.625.096v19.991Zm2 .155c.173.037.339.08.5.126V1.2l-.023-.016A6.024 6.024 0 0 0 10.5.882V20.3Zm4.25 1.341a12.77 12.77 0 0 1-.75-.178V2.49c.25.054.501.098.75.133V21.64Zm1 .17c1.275.172 2.94.244 5.25.12V1.854s-2.395.98-5.25.857v19.098Z'
      }
      fill="#5B5B5B"
    />
  </svg>
);

export default FirstIcon;
