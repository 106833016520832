import { IconProps } from '@/assets/Icons/IconProps';

const SVG = ({ fill = 'none', width = '16', height = '16', viewBox = '0 0 16 16' }: IconProps) => {
  return (
    <svg width={width} height={height} viewBox={viewBox} fill={fill}>
      <path
        d="M2 18L6 14"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="11.5"
        cy="8.5"
        r="6.5"
        stroke="currentColor"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SVG;
