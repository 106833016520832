import { IconProps } from '@/assets/Icons/IconProps';

const SVG = ({ isMobile }: IconProps) => {
  return (
    <svg width={37} height={37} viewBox="0 0 37 37" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d={
          isMobile
            ? 'M5.984 1.72c-2.39.846-4.422 1.578-4.515 1.625a.866.866 0 0 0-.277.228c-.207.272-.203.213-.203 2.8 0 2.568-.003 2.523.19 2.787.131.179.256.25.741.425l.413.148.016 3.124c.014 2.909.02 3.133.072 3.25a.896.896 0 0 0 .316.395c.058.036 1.762.654 3.787 1.373l3.681 1.309.285.003.284.002 3.71-1.317c4.043-1.436 3.857-1.359 4.044-1.678l.087-.149.016-3.159.015-3.159.437-.165c.24-.091.486-.199.545-.24.127-.086.273-.283.325-.436.027-.078.037-.777.036-2.525 0-2.36-.002-2.42-.065-2.559-.093-.206-.202-.338-.354-.427-.073-.043-2.104-.774-4.512-1.626C11.776.59 10.636.2 10.505.19c-.16-.01-.49.1-4.52 1.53Zm7.598 1.286c1.663.584 3.014 1.072 3.001 1.083a594.51 594.51 0 0 1-6.093 2.158c-.035 0-6.07-2.137-6.094-2.158-.03-.026 6.01-2.157 6.096-2.15.036.002 1.427.482 3.09 1.067Zm-3.93 6.107c0 .765-.01 1.39-.024 1.39-.013 0-1.581-.55-3.485-1.22L2.68 8.063l-.009-1.396-.008-1.395 3.494 1.226L9.65 7.724v1.39Zm8.639-1.055c-.026.022-6.904 2.445-6.94 2.445-.013 0-.023-.625-.023-1.39v-1.39l3.485-1.222L18.3 5.278l.008 1.38c.004.759-.003 1.389-.016 1.4ZM6.877 11.316l2.774.981v2.44c0 2.411 0 2.44-.061 2.42-.034-.01-1.297-.453-2.806-.983L4.04 15.21l-.008-2.452c-.006-1.958 0-2.45.032-2.438.021.009 1.288.457 2.813.996Zm10.07 1.433-.008 2.437-2.735.962c-1.504.529-2.766.97-2.806.98l-.07.02v-4.851l2.79-.99c1.534-.544 2.8-.99 2.813-.992.014-.002.02 1.094.016 2.434Z'
            : 'M10.37 2.903c-4.53 1.605-8.38 2.991-8.555 3.08a1.64 1.64 0 0 0-.525.433c-.392.516-.384.403-.384 5.307 0 4.863-.006 4.778.36 5.278.249.339.484.474 1.405.805l.781.281.03 5.92c.028 5.51.037 5.937.137 6.158.15.332.366.602.6.747.11.069 3.338 1.24 7.174 2.603l6.975 2.479.539.005.539.006 7.03-2.497c7.66-2.72 7.307-2.574 7.662-3.18l.164-.28.03-5.986.03-5.985.827-.314c.456-.172.92-.376 1.033-.454.24-.164.518-.536.616-.826.05-.148.07-1.474.068-4.784-.001-4.471-.004-4.587-.123-4.85-.176-.39-.384-.64-.671-.808-.14-.081-3.986-1.468-8.55-3.081C21.345.761 19.185.021 18.938.006c-.306-.02-.928.191-8.566 2.897Zm14.396 2.438c3.152 1.108 5.71 2.031 5.687 2.052-.046.04-11.48 4.089-11.546 4.089-.067 0-11.5-4.049-11.546-4.089-.057-.05 11.387-4.087 11.55-4.075.069.005 2.703.916 5.855 2.023ZM17.32 16.913c0 1.448-.02 2.633-.044 2.633-.025 0-2.997-1.04-6.605-2.311l-6.559-2.312-.016-2.644-.015-2.644 6.619 2.322 6.62 2.323v2.633Zm16.37-2c-.05.043-13.081 4.633-13.151 4.633-.024 0-.043-1.185-.043-2.633V14.28l6.604-2.317 6.604-2.317.015 2.614c.009 1.438-.005 2.632-.03 2.653Zm-21.627 6.175 5.256 1.858v4.623c.002 4.568 0 4.623-.116 4.585-.065-.02-2.457-.859-5.317-1.863l-5.198-1.826-.016-4.647c-.012-3.71 0-4.64.06-4.618.042.016 2.44.865 5.332 1.888Zm19.08 2.714-.015 4.617-5.182 1.823A786.764 786.764 0 0 1 20.63 32.1l-.135.037V27.54l.001-4.595 5.286-1.875a789.44 789.44 0 0 1 5.331-1.881c.025-.003.039 2.073.03 4.612Z'
        }
        fill="#fff"
      />
    </svg>
  );
};

export default SVG;
