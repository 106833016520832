import { IconProps } from '@/assets/Icons/IconProps';

const SVG = ({ fill = 'none', viewBox = '0 0 43.13 43.13' }: IconProps) => {
  return (
    <svg viewBox={viewBox} fill={fill} xmlns="http://www.w3.org/2000/svg">
      <rect x="0.231445" width="43.1306" height="43.1306" rx="7.52021" fill="#252525" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.0756 18.6824L23.0367 18.0416C22.9201 16.3808 23.9435 14.8638 25.5626 14.2753C26.1585 14.0661 27.1689 14.0399 27.8295 14.223C28.0886 14.3015 28.5808 14.563 28.9305 14.7984L29.5653 15.23L30.2647 15.0077C30.6533 14.89 31.1715 14.6938 31.4046 14.563C31.6248 14.4453 31.8191 14.3799 31.8191 14.4192C31.8191 14.6415 31.3399 15.4 30.9383 15.8184C30.3943 16.4069 30.5497 16.4592 31.6508 16.0669C32.3114 15.8446 32.3243 15.8446 32.1948 16.0931C32.1171 16.2238 31.7155 16.6815 31.2881 17.1C30.5627 17.8193 30.5238 17.8977 30.5238 18.4993C30.5238 19.4278 30.0834 21.3632 29.643 22.4225C28.8269 24.4102 27.0782 26.4634 25.3295 27.4965C22.8683 28.948 19.5911 29.3142 16.832 28.4642C15.9123 28.1765 14.332 27.4442 14.332 27.3134C14.332 27.2742 14.8113 27.2218 15.3942 27.2088C16.6118 27.1826 17.8295 26.8426 18.8657 26.241L19.5652 25.8226L18.7621 25.5479C17.6222 25.1556 16.5989 24.2533 16.3398 23.4033C16.2621 23.1287 16.288 23.1156 17.0134 23.1156L17.7647 23.1025L17.13 22.8017C16.3787 22.4225 15.6921 21.7817 15.3554 21.1278C15.1092 20.657 14.7984 19.467 14.889 19.3755C14.9149 19.3362 15.187 19.4147 15.4978 19.5193C16.3916 19.8463 16.5082 19.7678 15.9901 19.2185C15.0186 18.2247 14.7206 16.7469 15.187 15.3477L15.4072 14.72L16.2621 15.57C18.0108 17.2831 20.0704 18.3031 22.4279 18.6039L23.0756 18.6824Z"
        fill="white"
      />
    </svg>
  );
};

export default SVG;
