const SVG = ({ isMobile }: { isMobile?: boolean }) => {
  return (
    <svg width={!isMobile ? 33 : 23} height={!isMobile ? 21 : 13} fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d={
          isMobile
            ? 'M18.137 0a.722.722 0 0 0-.479.175l-1.116.992a14.522 14.522 0 0 0-5.042-.9C6.77.267 2.405 2.537.087 6.2a.544.544 0 0 0 0 .59c1.194 1.89 2.956 3.442 5.08 4.487l-.782.696a.555.555 0 0 0 0 .85.722.722 0 0 0 .478.176c.18 0 .351-.063.478-.176l4.215-3.747h.001l9.057-8.052A.57.57 0 0 0 18.814.6a.57.57 0 0 0-.198-.425.72.72 0 0 0-.478-.175ZM11.5 1.47c1.366 0 2.719.214 3.998.626l-1.617 1.437a4.46 4.46 0 0 0-2.381-.678c-2.257 0-4.101 1.64-4.101 3.645 0 .764.27 1.503.762 2.117L6.168 10.39C4.222 9.507 2.592 8.16 1.46 6.496 3.584 3.383 7.385 1.471 11.5 1.47Zm7.661 1.12a.7.7 0 0 0-.583.228c-.228.262-.174.64.12.843a10.957 10.957 0 0 1 2.84 2.839c-2.122 3.113-5.922 5.026-10.036 5.027-.71 0-1.42-.056-2.119-.172a.744.744 0 0 0-.506.096.6.6 0 0 0-.281.386c-.068.327.175.64.543.7.78.128 1.57.191 2.362.191 4.73-.001 9.095-2.272 11.412-5.938a.544.544 0 0 0 0-.59 12.16 12.16 0 0 0-3.384-3.49.728.728 0 0 0-.367-.126v.006ZM11.5 4.058c.5 0 .984.12 1.406.343L9.138 7.75A2.244 2.244 0 0 1 8.75 6.5c0-1.356 1.223-2.442 2.748-2.442Z'
            : 'M26.022 0a.97.97 0 0 0-.686.283l-1.602 1.602A18.773 18.773 0 0 0 16.5.43 18.79 18.79 0 0 0 .125 10.017a.972.972 0 0 0 0 .952 18.799 18.799 0 0 0 7.29 7.249L6.29 19.342a.972.972 0 0 0 1.372 1.374l6.048-6.052.001-.002L26.708 1.656A.971.971 0 0 0 26.022 0ZM16.5 2.375c1.96.001 3.9.345 5.736 1.01l-2.32 2.322a5.882 5.882 0 0 0-3.418-1.095c-3.238 0-5.884 2.648-5.884 5.888a5.89 5.89 0 0 0 1.095 3.42l-2.86 2.863a16.857 16.857 0 0 1-6.755-6.29 16.842 16.842 0 0 1 14.406-8.118Zm10.991 1.809a.976.976 0 0 0-.19 0 .97.97 0 0 0-.473 1.73 16.855 16.855 0 0 1 4.073 4.586 16.841 16.841 0 0 1-14.4 8.121 16.6 16.6 0 0 1-3.039-.279.97.97 0 0 0-1.13.78.971.971 0 0 0 .78 1.13c1.117.206 2.252.309 3.388.309h.002a18.79 18.79 0 0 0 16.373-9.592.973.973 0 0 0 0-.953 18.81 18.81 0 0 0-4.857-5.64.97.97 0 0 0-.526-.201v.009ZM16.5 6.554c.716 0 1.411.195 2.016.556l-5.404 5.408a3.954 3.954 0 0 1-.555-2.017A3.93 3.93 0 0 1 16.5 6.555Z'
        }
        fill="#7D7D7D"
      />
    </svg>
  );
};

export default SVG;
