import { IconProps } from '@/assets/Icons/IconProps';

const NinthIcon = ({ isMobile }: IconProps) => (
  <svg width={isMobile ? 15 : 25} height={isMobile ? 15 : 25} fill="none">
    <path
      d={
        isMobile
          ? 'M1.889 0c-.7 0-1.26.56-1.26 1.26v2.517c0 .7.56 1.26 1.26 1.26H0v1.259h7.555v-1.26H5.666c.699 0 1.26-.56 1.26-1.259V1.26C6.925.56 6.364 0 5.665 0H1.89Zm0 1.26h3.777v2.517H1.89V1.26Zm-.63 6.295v4.984h4.984v-1.26H2.518V7.556H1.26Zm7.503.577c-.7 0-1.26.56-1.26 1.26v2.517c0 .7.56 1.26 1.26 1.26h-1.89v1.259h7.556v-1.26h-1.889c.699 0 1.26-.56 1.26-1.259V9.391c0-.699-.561-1.259-1.26-1.259H8.762Zm0 1.26h3.777v2.517H8.762V9.391Z'
          : 'M3.273 0C2.062 0 1.09.97 1.09 2.182v4.363c0 1.211.97 2.182 2.182 2.182H0v2.182h13.09V8.727H9.819c1.211 0 2.182-.97 2.182-2.182V2.182C12 .97 11.03 0 9.818 0H3.273Zm0 2.182h6.545v4.363H3.273V2.182ZM2.182 13.09v8.636h8.636v-2.181H4.364V13.09H2.182Zm13 1c-1.211 0-2.182.97-2.182 2.182v4.363c0 1.211.97 2.182 2.182 2.182h-3.273V25H25v-2.182h-3.273c1.211 0 2.182-.97 2.182-2.182v-4.363c0-1.211-.97-2.182-2.182-2.182h-6.545Zm0 2.182h6.545v4.363h-6.545v-4.363Z'
      }
      fill="#5B5B5B"
    />
  </svg>
);

export default NinthIcon;
