import { IconProps } from '@/assets/Icons/IconProps';

const SeventhIcon = ({ isMobile }: IconProps) => (
  <svg width={isMobile ? 13 : 21} height={isMobile ? 12 : 20} fill="none">
    <path
      d={
        isMobile
          ? 'M9.97 4v.727c0 .066.055.12.121.12h.728a.122.122 0 0 0 .12-.12v-.728a.122.122 0 0 0-.12-.12h-.728a.122.122 0 0 0-.12.12ZM8.152 9.331h4.363A.484.484 0 0 0 13 8.847V2.303a.484.484 0 0 0-.485-.485H8.152C8.152.815 7.363 0 6.395 0H1.002A.122.122 0 0 0 .88.121v10.907c0 .067.054.122.12.122h5.394c.968 0 1.757-.815 1.757-1.818Zm0-1.09V2.908h3.757V8.24H8.152ZM9.97 6.422v.727c0 .067.055.122.121.122h.728a.122.122 0 0 0 .12-.122v-.727a.122.122 0 0 0-.12-.121h-.728a.122.122 0 0 0-.12.121Z'
          : 'M15.75 7.47v1.26c0 .116.095.21.21.21h1.26a.21.21 0 0 0 .21-.21V7.47a.21.21 0 0 0-.21-.21h-1.26a.21.21 0 0 0-.21.21Zm-3.15 9.24h7.56a.84.84 0 0 0 .84-.84V4.53a.84.84 0 0 0-.84-.84H12.6c0-1.738-1.368-3.15-3.045-3.15H.21A.21.21 0 0 0 0 .75v18.9c0 .116.095.21.21.21h9.345c1.677 0 3.045-1.412 3.045-3.15Zm0-1.89V5.58h6.51v9.24H12.6Zm3.15-3.15v1.26c0 .116.095.21.21.21h1.26a.21.21 0 0 0 .21-.21v-1.26a.21.21 0 0 0-.21-.21h-1.26a.21.21 0 0 0-.21.21Z'
      }
      fill="#5B5B5B"
    />
  </svg>
);

export default SeventhIcon;
